/* website: 3359-coupalbrassarnissan1
 * created at 2021-04-09 8:30 by slachkar
 */

// Import all makes and organization styles files
@import "../templates/makes/nissan.scss";
@import "../utils/icons.scss";

.widget-sr{
  @include clearfix;
  width: 100%;
  &.dealer__coupal-brassard-nissan-st-jean{
    .listing-tile .vehicle-certification{
      width: 100%;
    }
    .listing-tile .vehicle-certification .certification-badge{
      flex-wrap:initial !important;
    }
    .listing-tile .vehicle-certification .certification-badge-secondary{
      align-items:center;
      margin-top: 0 !important;
    }
    .vehicleBanner-personsViewed{
      color: #ffffff;
      background: rgba(0,0,0,0.5);
      icon.icon-eye.picto-showroom{
          color: #ffffff;
      }
      .amount.is-price{
          color: #ffffff;
      }
    }
  }
}